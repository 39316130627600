import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { SectionContainer } from "../components/micros"

const Contact = () => (
  <Layout>
    <Seo title="Kontakt" />
    <SectionContainer style={{ paddingTop: "100px" }}>
      {/* <h1>We are happy to help!</h1> */}
      {/* <span> Send us an email to </span><br /> */}
      {/* <a href="mailto:contact@summonjob.com">contact@summonjob.com </a> */}
    </SectionContainer  >
  </Layout>
)

export default Contact
